



















































import {
  Component, Mixins, Prop, Vue, Watch,
} from 'vue-property-decorator';

import EscrowQuickEntry from '@/views/quickEntry/EscrowQuickEntry.vue';
import NonEscrowQuickEntry from '@/views/quickEntry/NonEscrowQuickEntry.vue';
import PreventDirtyLeave from '@/mixins/PreventDirtyLeave.vue';

@Component({
  name: 'quick-entry-center',
  components: {
    EscrowQuickEntry,
    NonEscrowQuickEntry,
  },
})
export default class QuickEntryCenter extends Mixins(PreventDirtyLeave) {
  @Prop({
    type: String,
    default: null,
  }) readonly type!: string;

  private isEscrowDirty: boolean = false;
  private isNonEscrowDirty: boolean = false;

  updateUrl(event: string) {
    this.$router.push(`${this.$route.path}?type=${event}`);
  }

  isDirty() {
    return this.isEscrowDirty || this.isNonEscrowDirty;
  }
}
