



























































































































































































































































































































import {
  Component, Watch,
} from 'vue-property-decorator';
import {
  cloneDeep, difference, findLastIndex, tap, pick,
} from 'lodash';
import XLSX from 'xlsx';
import { DateTime } from 'luxon';
import { snakeCase } from 'change-case';
import Papa from 'papaparse';
import { saveAs } from 'file-saver';
import { AgGridVue } from 'ag-grid-vue';
import {
  ColDef, CellValueChangedEvent, SortChangedEvent,
} from 'ag-grid-community';

import LoanService from '@/services/loans';

import Parcel, { ParcelAgency } from '@/entities/Parcel';
import Loan from '@/entities/Loan';
import Agency from '@/entities/Agency';
import Lender from '@/entities/Lender';
import Address from '@/entities/Address';
import { INonEscrowHistory } from '@/entities/IParcel';
import { DelinquentTaxCollectingYearUtil, IDelinquentTaxCollectingOffice } from '@/entities/IAgency';

import { JsonPatchEntry, JsonPatchOperator, JsonPatchPayload } from '@/helpers/vuelidateToPatch';

import GridOmnifilter from '@/components/inputs/GridOmnifilter.vue';

import AgencySearch from '@/views/agencies/AgencySearch.vue';
import LenderSearch from '@/views/lenders/LenderSearch.vue';

import states from '@/data/states';

import buildOnePageWorkBook, { XLSTypes, XLSWriteOptions } from '@/helpers/exports/xls/ExcelUtil';

import BackgroundGridReport from '@/views/reports/BackgroundGridReport.vue';
import { AxiosError } from 'axios';
import ExportDataParams from '../reports/models/ExportDataParams';

interface ParcelSummary {
  [key: string]: any,

  parcel?: Parcel,
  loan?: Loan,
  agency?: Agency,

  delqAgencyNumber?: string,
  agencyNumbers?: string[],
  agencyIds?: string[],
  lenderNumber?: string,
  loanId?: string,
  parcelId?: string,
  loanNumber?: string,
  addressSummary?: string,
  name?: string,
  companyName?: string,
  parcelNumber?: string,
  verified?: boolean,
  status?: string,
  notes?: string,
  year?: string,
  type?: string,
  base?: number,
  amountDue?: number,
  reportedDate?: string,
  parcelAgencyIds?: string[],
  nonEscrowHistories?: INonEscrowHistory[],
  existing: boolean,
  index?: number
}

interface QuickEntryCreateRow {
  [key: string]: any,

  parcel?: Parcel,
  loan?: Loan,
  agency?: ParcelAgency,
  year?: string,
  status?: string,
  base?: number,
  reportedDate?: string,
}

@Component({
  name: 'non-escrow-quick-entry',
  components: {
    AgGridVue,
    GridOmnifilter,
    AgencySearch,
    LenderSearch,
  },
})
export default class NonEscrowQuickEntry extends BackgroundGridReport<Loan, ParcelSummary> {
  protected pageSizes = [500, 2500];

  private loanService: LoanService = new LoanService();

  private useDelqTaxCollectingOffice: boolean = true;

  private delqAgencies: Agency[] = [];
  private selectedLenders: Lender[] = [];
  private pickedStates: string[] = [];
  private states: string[] = states.map((state) => state.value);
  private stateSearchInput: string = '';

  private protectedCells: { [index: string]: {[index: string]: boolean } } = {};

  private displayReportedDateRows: boolean = false;

  private parcelTypeItems: any[] = [{
    text: 'Escrow',
    value: 'E',
  }, {
    text: 'Non-Escrow',
    value: 'N',
  }, {
    text: 'Escrow/Non-Escrow',
    value: 'EN',
  }, {
    text: 'All',
    value: 'All',
  }];

  private delinquentItems: any[] = [{
    text: 'Delinquent',
    value: 'DELQ',
  }, {
    text: 'All',
    value: 'All',
  }];

  private agencyNumber: string = '';
  private parcelType: string = this.parcelTypeItems[3].value;
  private delinquentFilter: string = this.delinquentItems[0].value;

  private isSaving: boolean = false;

  // Mark all variables
  private reportedDateDialog: boolean = false;
  private selectedDate: string = DateTime.local().toFormat('MM/dd/yyyy');
  private overwriteCheckbox: boolean = false;
  private rules: any = {
    required: (value: any) => !!value || 'Required.',
    validDate: (value: any) => DateTime.fromFormat(value, 'MM/dd/yyyy').isValid || 'Must be a valid MM/DD/YYYY date',
  };

  // Add new row
  private addNewRowDialog: boolean = false;
  private oldRow: QuickEntryCreateRow = {};
  private newRow: QuickEntryCreateRow = {};
  private yearOptions: string[] = [];

  // Update operations
  private parcelMapper = (key: string, val: number, summary: ParcelSummary) => ([{
    op: JsonPatchOperator.replace,
    path: this.parcelPathResolver(key, summary.parcelId),
    value: val,
  }]);

  private nonEscrowHistoryMapper = (key: string, val: number, summary: ParcelSummary) => summary.nonEscrowHistories.map((historyEntry, index) => ({
    op: JsonPatchOperator.replace,
    path: this.nonEscrowPathResolver(key, historyEntry.parcelId, summary.parcelAgencyIds[index], historyEntry.parcelNonEscrowHistoryId),
    value: val,
  }));

  private changeMap: Map<ParcelSummary, Map<string, JsonPatchEntry>> = new Map();
  private createMap: Map<ParcelSummary, any> = new Map();
  private converterMap: Map<string, (val: number, summary: ParcelSummary) => JsonPatchEntry[]> = new Map([[
    'status', this.nonEscrowHistoryMapper.bind(null, 'status'),
  ], [
    'base', this.nonEscrowHistoryMapper.bind(null, 'base'),
  ], [
    'reportedDate', this.nonEscrowHistoryMapper.bind(null, 'reported_date'),
  ], [
    'verified', this.parcelMapper.bind(null, 'verified'),
  ]]);

  private parcelPathResolver(key: string, parcelId: string) {
    return `/parcels/${parcelId}/${key}`;
  }

  private nonEscrowPathResolver(key: string, parcelId: string, parcelAgencyId: string, parcelNonEscrowHistoryId: string) {
    return `/parcels/${parcelId}/agencies/${parcelAgencyId}/non_escrow_history/${parcelNonEscrowHistoryId}/${key}`;
  }

  // Grid setup (some in mounted hooks)
  private rowClassRules: any = {
    'new-row': (params: any) => !params.data.existing,
  };

  protected columnDefs: ColDef[] = [
    {
      headerName: 'DTCO #',
      field: 'delqAgencyNumber',
      sortable: false,
    },
    {
      headerName: "Agency #'s",
      field: 'agencyNumbers',
      sortable: false,
    },
    {
      headerName: 'Lender #',
      field: 'lenderNumber',
    },
    {
      headerName: 'Loan #',
      field: 'loanNumber',
    },
    {
      headerName: 'Address',
      field: 'addressSummary',
      sortable: false,
    },
    {
      headerName: 'Name',
      field: 'name',
      sortable: false,
    },
    {
      headerName: 'Parcel #',
      field: 'parcelNumber',
    },
    {
      headerName: 'Parcel Type',
      field: 'type',
    },
    {
      headerName: 'Year',
      field: 'year',
      sortable: false,
    },
    {
      headerName: 'Verified',
      field: 'verified',
      width: 100,
      sortable: false,
      type: 'boolean',
      editable: true,
    },
    {
      headerName: 'Reported Date',
      field: 'reportedDate',
      cellEditor: 'dateCellEditor',
      editable: true,
      sortable: false,
      type: 'date',
    },
    {
      headerName: 'Paid/Delq',
      field: 'status',
      cellEditor: 'comboboxEditor',
      cellEditorParams: {
        values: ['PAID', 'DELQ'],
      },
      editable: true,
      sortable: false,
    },
    {
      headerName: 'Base Tax',
      field: 'base',
      flex: 1,
      minWidth: 200,
      editable: true,
      sortable: false,
      type: 'currency',
    },
  ];

  @Watch('hasChanges')
  hasChangesUpdated(val: boolean) {
    if (val) {
      this.$emit('update');
    } else {
      this.$emit('clear');
    }
  }

  @Watch('displayReportedDateRows')
  async ondisplayReportedDateRowsChanged(val: boolean) {
    this.refreshRows();
  }

  @Watch('newRow')
  onNewRowChanged(val: QuickEntryCreateRow, oldVal: QuickEntryCreateRow) {
    if (val.loan && (!oldVal.loan || val.loan.loanId !== oldVal.loan.loanId)) {
      val.parcel = null;
    }

    if (val.parcel && (!oldVal.parcel || val.parcel.parcelId !== oldVal.parcel.parcelId)) {
      val.agency = null;

      if (val.loan.parcels.length === 1) {
        [val.parcel] = val.loan.parcels;
      }
    }

    if (val.agency && (!oldVal.agency || val.agency.parcelAgencyId !== oldVal.agency.parcelAgencyId)) {
      val.year = null;

      if (val.parcel.agencies.length === 1) {
        [val.agency] = val.parcel.agencies;
      }
    }
  }

  updateNewRow(value: any, field: keyof QuickEntryCreateRow) {
    const row = tap(
      cloneDeep(this.newRow),
      (v) => {
        v[field] = value;
      },
    );

    this.newRow = row;
  }

  // Computed
  get hasRequiredInput(): boolean {
    // eslint-disable-next-line no-mixed-operators
    return this.parcelType && this.delinquentFilter && (this.agencyNumbers.length > 0 && this.useDelqTaxCollectingOffice) || (this.pickedStates.length > 0 && !this.useDelqTaxCollectingOffice);
  }

  get advancedSearch(): any {
    const advancedSearch = this.useDelqTaxCollectingOffice ? {
      delinquent_tax_collecting_offices: this.agencyNumbers,
    } : {
      agency_states: this.pickedStates,
    };

    if (this.selectedLenders.length > 0) {
      Object.assign(advancedSearch, {
        lender_numbers: this.selectedLenders.map((lender) => lender.id),
      });
    }

    if (this.delinquentFilter === this.delinquentItems[0].value) {
      Object.assign(advancedSearch, {
        delinquent_filter: 'delinquent',
      });
    }

    return advancedSearch;
  }

  get hasChanges(): boolean {
    return this.createMap.size > 0 || this.changeMap.size > 0;
  }

  get agencyNumbers(): string[] {
    return this.delqAgencies.map((agency: Agency) => agency.capAgency);
  }

  get delqAgencyIds(): string[] {
    return this.delqAgencies.map((agency: Agency) => agency.agencyId);
  }

  get summaryLoans(): Loan[] {
    const allLoanSet = this.results.reduce((loanSet: Set<Loan>, parcelSummary: ParcelSummary) => {
      loanSet.add(parcelSummary.loan);
      return loanSet;
    }, new Set());

    return Array.from(allLoanSet.values());
  }

  get validYears(): string[] {
    if (!this.newRow.agency) {
      return [];
    }

    const existingYearSet = this.results.reduce((yearSet, summary) => {
      if (summary.parcelAgencyIds.findIndex((id) => id === this.newRow.agency.parcelAgencyId) !== -1) {
        yearSet.add(summary.year);
      }

      return yearSet;
    }, new Set<string>());

    const existingYears = Array.from(existingYearSet.values());

    return difference(this.yearOptions, existingYears);
  }

  get isNewRowValid(): boolean {
    return Boolean(this.newRow.loan && this.newRow.parcel && this.newRow.agency && this.newRow.year);
  }

  // Mixins
  isDirty() {
    return this.hasChanges;
  }

  // Hooks
  async created() {
    const nextYear = (new Date()).getFullYear() + 1;

    for (let i = 0; i < 20; i += 1) {
      const relevantYear = nextYear - i;
      this.yearOptions.push(relevantYear.toString());
    }

    // Set the reported date editable state
    const columnDef = this.columnDefs.find((def) => def.field === 'reportedDate');
    columnDef.editable = !this.isExactlyUser && !this.isExactlyTrainee;
  }

  // Methods
  onGridReadyComplete() {
    this.gridApi.showNoRowsOverlay();
    // Commenting this line because, the Property 'getSortModel' does not exist on type 'GridApi<any>'
    // this.gridApi.setSortModel([{
    //   colId: 'parcelNumber',
    //   sort: 'asc',
    // }]);
  }

  onSortChanged(params: SortChangedEvent) {
    this.onBackgroundSortChanged(params);
    this.refreshRows();
  }

  refreshRows() {
    this.results = [];
    this.gridApi.setRowData([]);

    this.getAllData();
  }

  getParams() {
    return {
      advanced_search: this.advancedSearch,
      search_type: 'non_escrow_history',
      limit_final_query_flag: true,
    };
  }

  async getRows(params: any, limit: number, offset: number) {
    const finalParams = { limit, offset, ...params };

    return this.makeCancellableRequest(this.loanService.getAllLoans, finalParams)
      .then((value) => {
        const { results } = value;
        return results;
      });
  }

  convertResults(results: Loan[]): ParcelSummary[] {
    const parcels = results.reduce<Parcel[]>((allParcels: Parcel[], loan: Loan) => {
      const validParcels = loan.parcels.filter((parcel) => {
        const parcelAgenciesWithMatchingNonEscrow = parcel.agencies.filter((parcelAgency) => {
          const matchesAgenciesIfDelq = !this.useDelqTaxCollectingOffice || this.delqAgencyIds.includes(parcelAgency.delinquentTaxCollectingId);

          return matchesAgenciesIfDelq;
        });

        return parcel.active && (parcel.parcelType === this.parcelType || this.parcelType === 'All') && parcelAgenciesWithMatchingNonEscrow.length > 0;
      });

      allParcels.push(...validParcels);
      return allParcels;
    }, []);

    const parcelSummaries = parcels.reduce<ParcelSummary[]>((allParcels, parcel) => {
      // Get the relevant loan
      const loan: Loan = results.find((candidateLoan) => candidateLoan.loanNumber === parcel.loanNumber);

      // Get the relevant parcel agencies for each parcel - each gets a row
      const relevantParcelAgencies: ParcelAgency[] = this.useDelqTaxCollectingOffice ? (this.delqAgencyIds as string[]).reduce<ParcelAgency[]>((allParcelAgencies, agencyId) => {
        const foundParcelAgencies = parcel.agencies.filter(
          (agency) => agency.delinquentTaxCollectingId && agency.delinquentTaxCollectingId === agencyId,
        );

        allParcelAgencies.push(...foundParcelAgencies);

        return allParcelAgencies;
      }, []) : parcel.agencies;

      const relevantNonEscrowHistoryCollections: INonEscrowHistory[][] = relevantParcelAgencies.map(
        (parcelAgency) => parcelAgency.nonEscrowHistory,
      );

      // Map indexed by year, value is the NE history
      const parcelMap: Map<string, { delqAgency: IDelinquentTaxCollectingOffice, agencies: ParcelAgency[], histories: INonEscrowHistory[] }> = new Map();

      // Build the parcel summaries to show the user
      relevantParcelAgencies.forEach((agency, index) => {
        const delqAgency: IDelinquentTaxCollectingOffice = agency.delinquentTaxCollectingOffice;
        const agencyYear = delqAgency && delqAgency.nonEscrowCollectingYear ? DelinquentTaxCollectingYearUtil.collectingYearToYear(delqAgency.nonEscrowCollectingYear) : null;
        if (!delqAgency || !delqAgency.nonEscrowCollectingYear || !delqAgency.capAgency) {
          return;
        }
        const existingHistories = relevantNonEscrowHistoryCollections[index];
        const collectingYearEntry = existingHistories.find((entry) => entry.year === agencyYear);

        // Add a blank row if the collecting year has not been filled in yet
        if (!collectingYearEntry && agencyYear) {
          const mapEntry = parcelMap.get(agencyYear);
          if (!mapEntry) {
            parcelMap.set(agencyYear, { delqAgency, agencies: [agency], histories: [null] });
          } else {
            mapEntry.agencies.push(agency);
            mapEntry.histories.push(null);
          }
        }

        // Add a row for each status-matching history
        existingHistories.filter(
          (history) => (history.status.value && history.status.value.includes(this.delinquentFilter.toUpperCase())) || this.delinquentFilter === 'All',
        ).forEach((history) => {
          const mapEntry = parcelMap.get(history.year);
          if (!mapEntry) {
            parcelMap.set(history.year, { delqAgency, agencies: [agency], histories: [history] });
          } else {
            mapEntry.agencies.push(agency);
            mapEntry.histories.push(history);
          }
        });
      });

      parcelMap.forEach((entry, year) => {
        allParcels.push({
          parcel,
          loan,

          delqAgencyNumber: entry.delqAgency ? entry.delqAgency.capAgency : '',
          agencyNumbers: entry.agencies.map((agency) => agency.capAgency),
          agencyIds: entry.agencies.map((agency) => agency.agencyId),
          lenderNumber: parcel.lenderNumber,
          loanId: loan.loanId,
          parcelId: parcel.parcelId,
          loanNumber: parcel.loanNumber,
          addressSummary: parcel.address ? Address.firstLineAddress(parcel.address.value) : '',
          name: loan.companyName || loan.borrowerName,
          parcelNumber: parcel.parcelNumber,
          type: parcel.parcelType,
          verified: parcel.verified,
          status: entry.histories[0] && entry.histories[0].status ? entry.histories[0].status.value : '',
          notes: entry.histories[0] ? entry.histories[0].notes : '',
          year,
          base: entry.histories[0] ? entry.histories[0].base : null,
          amountDue: entry.histories[0] ? entry.histories[0].amountDue : null,
          reportedDate: entry.histories[0] ? entry.histories[0].reportedDate : null,
          parcelAgencyIds: entry.agencies.map((agency) => agency.parcelAgencyId),
          nonEscrowHistories: entry.histories,
          existing: Boolean(entry.histories.find((history) => history !== null)),
        });
      });

      return allParcels;
    }, []);

    return parcelSummaries.map(
      (parcelSummary, index) => ({ ...parcelSummary, index }),
    ).filter((parcelSummary) => this.reportDateFilter(parcelSummary.reportedDate));
  }

  handleCellChangeEvent(event: CellValueChangedEvent) {
    const data: ParcelSummary = event.node.data as ParcelSummary;
    const key = event.colDef.field;
    if (!(data.loanId in this.protectedCells)) {
      this.protectedCells[data.loanId] = { };
      this.protectedCells[data.loanId][key] = event.oldValue === true
    }

    if (event.oldValue === true && (key === 'verified') && this.protectedCells[data.loanId][key] === true) {
      event.node.data[key] = true;
      event.node.setData(event.node.data);
      return;
    }

    const newSummary = this.addToChangeList(event.newValue, event.node.data as ParcelSummary, event.colDef.field);
    event.node.setData(newSummary);
  }

  addToChangeList(value: any, summary: ParcelSummary, key: string) {
    const header = key;
    const nonEscrowHeaders = ['reportedDate', 'status', 'base'];

    if (key !== 'verified') {
      summary.verified = false;
      this.addChangePatch(summary.verified, 'verified', summary, nonEscrowHeaders);
    }

    summary[header] = value;
    this.addChangePatch(value, header, summary, nonEscrowHeaders);

    return summary;
  }

  addChangePatch(value: any, field: string, summary: ParcelSummary, exclusions: string[] = []) {
    const patchMap = this.changeMap.get(summary) || new Map();
    const createEntry = this.createMap.get(summary) || {};
    const converter = this.converterMap.get(field);

    if (!summary.existing && exclusions.findIndex((escrowHeader) => escrowHeader === field) !== -1) {
      createEntry[snakeCase(field)] = value;
      this.createMap.set(summary, createEntry);
      this.createMap = new Map(this.createMap);
    } else if (converter) {
      patchMap.set(field, converter(value, summary)[0]);
      this.changeMap.set(summary, patchMap);
      this.changeMap = new Map(this.changeMap);
    } else {
      patchMap.set(field, {
        op: JsonPatchOperator.replace,
        path: field,
        value,
      });
      this.changeMap.set(summary, patchMap);
      this.changeMap = new Map(this.changeMap);
    }
  }

  async submitChanges() {
    const payload: JsonPatchPayload = [];

    this.isSaving = true;

    // Run through updates
    this.changeMap.forEach((patchMap: Map<string, JsonPatchEntry>, summary: ParcelSummary) => {
      const parcelPatches = Array.from(patchMap.values());
      parcelPatches.forEach((patch) => {
        patch.path = `/${summary.loanId}${patch.path}`;
      });
      payload.push(...parcelPatches);
    });

    // Run through creations
    this.createMap.forEach((entry: any, summary: ParcelSummary) => {
      if (!summary.parcelAgencyIds) return;

      summary.parcelAgencyIds.forEach((parcelAgencyId, index) => {
        const fullEntry = {
          ...entry,
          agency_id: summary.agencyIds[index],
          parcel_id: summary.parcelId,
          parcel_agency_id: parcelAgencyId,
          year: summary.year,
        };

        payload.push({
          op: JsonPatchOperator.add,
          path: `/${summary.loanId}/parcels/${summary.parcelId}/agencies/${parcelAgencyId}/non_escrow_history/-`,
          value: fullEntry,
        });
      });
    });

    try {
      const response = await this.loanService.batchPatchLoans(payload);
      this.showSuccess(`Updated ${response.length} loan(s) successfully.`);
    // Cast err to AxiosError - Property 'response' does not exist on type 'unknown'.Vetur
    } catch (err) {
      const e = err as AxiosError
      if (e.response && e.response.status >= 400) {
        this.showError(`Could not update loans - ${e.response.data.message}`);
      }
    } finally {
      this.isSaving = false;
      this.changeMap = new Map();
      this.createMap = new Map();
      this.protectedCells = {};

      this.refreshRows();
    }
  }

  determineLoanName(item: Loan): string {
    return `${item.borrowerName || item.borrowerName2 || item.companyName} - ${item.loanNumber}`;
  }

  determineParcelName(item: Parcel): string {
    return item.parcelNumber;
  }

  determineAgencyName(item: Agency): string {
    let nameString = item.name;

    if (item.address && item.address.value && item.address.value.county) {
      nameString += `, ${item.address.value.county}`;
    }

    if (item.address && item.address.value && item.address.value.state) {
      nameString += `, ${item.address.value.state}`;
    }

    nameString += `, ${item.capAgency}`;

    return nameString;
  }

  markAll(key: string, value: any) {
    if (!(this.$refs.dateForm as any).validate()) {
      return;
    }

    this.results.forEach((parcel: ParcelSummary) => {
      if (this.overwriteCheckbox || (!this.overwriteCheckbox && (!parcel[key] || parcel[key].length === 0))) {
        this.addToChangeList(value, parcel, key);
        parcel[key] = value;
      }
    });

    this.gridApi.refreshCells({
      columns: [key],
    });
  }

  setCurrentDate() {
    this.selectedDate = DateTime.local().toFormat('MM/dd/yyyy');
  }

  reportDateFilter(value: string): boolean {
    return (this.displayReportedDateRows && value && value.length > 2) || (!value || value.length === 0);
  }

  addNewRow() {
    if (!this.isNewRowValid) {
      console.error('Tried to create a new row when invalid.');
      return;
    }

    const delqAgency: any = this.useDelqTaxCollectingOffice
      ? this.delqAgencies.find((candidateAgency: Agency) => candidateAgency.agencyId === this.newRow.agency.delinquentTaxCollectingId)
      : this.newRow.agency.delinquentTaxCollectingOffice;

    // TODO: Roll the new entry into an existing one if necessary
    // - nonEscrowHistories
    const finalRow: ParcelSummary = {
      ...this.newRow,

      delqAgencyNumber: delqAgency ? delqAgency.capAgency : null,
      lenderNumber: this.newRow.parcel.lenderNumber,
      loanId: this.newRow.loan.loanId,
      parcelId: this.newRow.parcel.parcelId,
      loanNumber: this.newRow.loan.loanNumber,
      addressSummary: this.newRow.parcel.address ? Address.firstLineAddress(this.newRow.parcel.address.value) : '',
      name: this.newRow.loan.companyName || this.newRow.loan.borrowerName,
      parcelNumber: this.newRow.parcel.parcelNumber,
      type: this.newRow.parcel.parcelType,
      verified: false,
      status: this.newRow.status,
      notes: null,
      year: this.newRow.year,
      reportedDate: null,
      parcelAgencyIds: [this.newRow.agency.parcelAgencyId],
      agencyNumbers: [this.newRow.agency.capAgency],
      agencyIds: [this.newRow.agency.agencyId],
      existing: false,
    };

    // Put the parcel summary next to the same loan/parcel/agency combo
    const insertIndex = findLastIndex(
      this.results,
      (summary) => summary.loanId === finalRow.loanId && summary.parcelId === finalRow.parcelId,
    ) + 1;

    // Insert new row into results set and grid data
    this.results.splice(insertIndex, 0, finalRow);
    this.gridApi.applyTransaction({
      add: [finalRow],
      addIndex: insertIndex,
    });

    this.createMap.set(finalRow, {
      reported_date: this.newRow.reportedDate,
      status: this.newRow.status,
      base: this.newRow.base,
    });

    this.createMap = new Map(this.createMap);

    this.newRow = {};
  }

  private calculateDataOptions(): XLSWriteOptions[] {
    const typedColumns = this.columnDefs.filter((columnDef) => Boolean(columnDef.type));

    return typedColumns.map((column) => {
      const type = Array.isArray(column.type) ? column.type[0] : column.type;

      if (type === 'verified') {
        return {
          header: column.field,
          type: 'boolean',
        }
      }

      return {
        header: column.field,
        type: column.type as XLSTypes,
      };
    });
  }

  exportTable() {
    this.exportData(new ExportDataParams({
      file: 'NonEscrowQuickEntry',
    }))
  }

  exportData(params: ExportDataParams) {
    const pickSet = new Set(params.ignoreGrid ? [] : this.columnDefs.map((colDef) => colDef.field));
    params.inclusions.forEach((inclusion) => pickSet.add(inclusion));
    params.exclusions.forEach((exclusion) => pickSet.delete(exclusion));

    const parsedData = this.results.map((row) => pick(row, Array.from(pickSet)));
    if (params.format === 'xlsx') {
      const wb = buildOnePageWorkBook(parsedData, this.calculateDataOptions(), 'Report');
      XLSX.writeFile(wb, `${params.file}.xlsx`, {
        type: 'file',
      });
    } else if (params.format === 'csv') {
      const csv = Papa.unparse(parsedData);
      const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });

      saveAs(blob, `${params.file}.csv`);
    }
  }
}
