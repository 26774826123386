







































































































































































import {
  Component, Watch,
} from 'vue-property-decorator';
import { pick, result } from 'lodash';
import XLSX from 'xlsx';
import { DateTime } from 'luxon';
import { snakeCase } from 'change-case';
import { AgGridVue } from 'ag-grid-vue';
import {
  ColDef, CellValueChangedEvent, SortChangedEvent,
} from 'ag-grid-community';
import Papa from 'papaparse';
import { saveAs } from 'file-saver';

import LoanService from '@/services/loans';

import Parcel, { ParcelAgency } from '@/entities/Parcel';
import Loan from '@/entities/Loan';
import Agency from '@/entities/Agency';
import Lender from '@/entities/Lender';
import Address from '@/entities/Address';
import { IEscrowHistory } from '@/entities/IParcel';
import EscrowType from '@/entities/EscrowType';
import Term from '@/entities/Term';

import { JsonPatchOperator, JsonPatchPayload, JsonPatchEntry } from '@/helpers/vuelidateToPatch';

import GridOmnifilter from '@/components/inputs/GridOmnifilter.vue';
import ActionButton from '@/components/inputs/ActionButton.vue';

import AgencySearch from '@/views/agencies/AgencySearch.vue';
import LenderSearch from '@/views/lenders/LenderSearch.vue';

import buildOnePageWorkBook, { XLSTypes, XLSWriteOptions } from '@/helpers/exports/xls/ExcelUtil';

import BackgroundGridReport from '@/views/reports/BackgroundGridReport.vue';
import { AxiosError } from 'axios';
import ExportDataParams from '../reports/models/ExportDataParams';

interface ParcelSummary {
  [key: string]: any,

  parcel?: Parcel,
  loan?: Loan,
  agency?: ParcelAgency,

  agencyNumber?: string,
  agencyId?: string,
  lenderNumber?: string,
  loanId?: string,
  parcelId?: string,
  lenderId?: string,
  loanNumber?: string,
  addressSummary?: string,
  name?: string,
  type?: EscrowType,
  companyName?: string,
  parcelNumber?: string,
  verified?: boolean,
  problem?: boolean,
  parcelNotes?: string,
  reportedDate?: Date,
  batchNumber?: string,
  parcelAgencyId?: string,
  escrowHistory?: IEscrowHistory,
  amountReported?: string,
  zeroVerifiedReason?: string,
  existing: boolean,
  index?: number
}

@Component({
  name: 'escrow-quick-entry',
  components: {
    AgGridVue,
    GridOmnifilter,
    AgencySearch,
    LenderSearch,
    ActionButton,
  },
})
export default class EscrowQuickEntry extends BackgroundGridReport<Loan, ParcelSummary> {
  protected pageSizes = [500, 2500];

  private loanService: LoanService = new LoanService();

  private loans: Loan[] = [];
  private agencies: Agency[] = [];
  private selectedLenders: Lender[] = [];

  private year: string = '';
  private term: string = null;

  private isSaving: boolean = false;

  private calculatedTotalTaxesDue = '0.00';

  private escrowYearItems: string[] = [];

  private protectedCells: { [index: string]: {[index: string]: boolean } } = {};

  private displayReportedDateRows: boolean = false;

  // Mark all variables
  private reportedDateDialog: boolean = false;
  private selectedDate: string = DateTime.local().toFormat('MM/dd/yyyy');
  private overwriteCheckbox: boolean = false;
  private rules: any = {
    required: (value: any) => !!value || 'Required.',
    validDate: (value: any) => DateTime.fromFormat(value, 'MM/dd/yyyy').isValid || 'Must be a valid MM/DD/YYYY date',
  };

  // Update operations
  private parcelMapper = (key: string, val: number, summary: ParcelSummary) => ([{
    op: JsonPatchOperator.replace,
    path: this.parcelPathResolver(key, summary.parcelId),
    value: val,
  }]);

  private escrowHistoryMapper = (key: string, val: number, summary: ParcelSummary) => ([{
    op: JsonPatchOperator.replace,
    path: this.escrowPathResolver(key, summary.parcelId, summary.parcelAgencyId, summary.escrowHistory.parcelEscrowHistoryId),
    value: val,
  }]);

  private changeMap: Map<ParcelSummary, Map<string, JsonPatchEntry>> = new Map();
  private createMap: Map<ParcelSummary, any> = new Map();
  private converterMap: Map<string, (val: number, summary: ParcelSummary) => JsonPatchEntry[]> = new Map([[
    'parcelNumber', this.parcelMapper.bind(null, 'parcel_number'),
  ], [
    'amountReported', this.escrowHistoryMapper.bind(null, 'amount_reported'),
  ], [
    'zeroVerifiedReason', this.escrowHistoryMapper.bind(null, 'zero_verified_reason'),
  ], [
    'reportedDate', this.escrowHistoryMapper.bind(null, 'reported_date'),
  ], [
    'batchNumber', this.escrowHistoryMapper.bind(null, 'batch_number'),
  ], [
    'parcelNotes', this.parcelMapper.bind(null, 'parcel_notes'),
  ], [
    'verified', this.parcelMapper.bind(null, 'verified'),
  ], [
    'problem', this.parcelMapper.bind(null, 'problem'),
  ]]);

  private parcelPathResolver(key: string, parcelId: string) {
    return `/parcels/${parcelId}/${key}`;
  }

  private escrowPathResolver(key: string, parcelId: string, parcelAgencyId: string, parcelEscrowHistoryId: string) {
    return `/parcels/${parcelId}/agencies/${parcelAgencyId}/escrow_history/${parcelEscrowHistoryId}/${key}`;
  }

  private termItems = Object.keys(Term).filter((k) => typeof Term[k as keyof typeof Term] === 'string').map((k) => ({
    text: Term[k as keyof typeof Term],
    value: k,
  }));

  // Grid setup (some in mounted hooks)
  private rowClassRules: any = {
    'new-row': (params: any) => !params.data.existing,
  };

  protected columnDefs: ColDef[] = [
    {
      headerName: 'Lender #',
      field: 'lenderNumber',
      width: 100,
      sortable: false,
      editable: false,
    },
    {
      headerName: 'Loan #',
      field: 'loanNumber',
      editable: false,
    },
    {
      headerName: 'Address',
      field: 'addressSummary',
      sortable: false,
      editable: false,
    },
    {
      headerName: 'Name',
      field: 'name',
      editable: false,
    },
    {
      headerName: 'Parcel Type',
      field: 'type',
      width: 125,
    },
    {
      headerName: 'Agency #',
      field: 'agencyNumber',
      sortable: false,
    },
    {
      headerName: 'Parcel #',
      field: 'parcelNumber',
    },
    {
      headerName: 'Verified',
      field: 'verified',
      width: 100,
      sortable: false,
      type: 'boolean',
      // editable: (params: IsColumnFuncParams) => this.isCellEditable(params),
      editable: true,
    },
    {
      headerName: 'Problem',
      field: 'problem',
      width: 100,
      sortable: false,
      type: 'boolean',
      // editable: (params: IsColumnFuncParams) => this.isCellEditable(params),
      editable: true,
    },
    {
      headerName: 'Reported Date',
      field: 'reportedDate',
      cellEditor: 'dateCellEditor',
      sortable: false,
      editable: true,
      type: 'date',
    },
    {
      headerName: 'Batch #',
      field: 'batchNumber',
      sortable: false,
      editable: true,
    },
    {
      headerName: 'Taxes Due',
      field: 'amountReported',
      sortable: false,
      editable: true,
      type: 'currency',
    },
    {
      headerName: 'Zero Value Reason',
      field: 'zeroVerifiedReason',
      editable: true,
      sortable: false,
    },
    {
      headerName: 'Parcel Notes',
      field: 'parcelNotes',
      sortable: false,
      editable: true,
      flex: 1,
      minWidth: 200,
    },
  ];

  @Watch('hasChanges')
  hasChangesUpdated(val: boolean) {
    if (val) {
      this.$emit('update');
    } else {
      this.$emit('clear');
    }
  }

  @Watch('displayReportedDateRows')
  async ondisplayReportedDateRowsChanged(val: boolean) {
    this.refreshRows();
  }

  @Watch('userInput', { deep: true })
  onUserInputChanged(val: any) {
    this.changeMap = new Map();
    this.createMap = new Map();
  }

  // Computed
  get userInput() {
    return {
      agencies: this.agencies,
      year: this.year,
      term: this.term,
    };
  }

  get hasRequiredInput(): boolean {
    return this.year && this.term && this.agencies.length > 0;
  }

  get advancedSearch(): any {
    const advancedSearch = {
      agency_numbers: this.agencyNumbers,
      parcel_type_in: ['E', 'EN'],
    };

    if (this.selectedLenders.length > 0) {
      Object.assign(advancedSearch, {
        lender_numbers: this.selectedLenders.map((lender) => lender.id),
      });
    }

    return advancedSearch;
  }

  get hasChanges(): boolean {
    return this.createMap.size > 0 || this.changeMap.size > 0;
  }

  get parcels(): Parcel[] {
    if (!this.year || !this.term) return [];

    const parcels = (this.loans as Loan[]).reduce<Parcel[]>((allParcels: Parcel[], loan: Loan) => {
      const validParcels = loan.parcels.filter((parcel) => {
        const matchingParcelAgencies = parcel.agencies.filter(
          (parcelAgency) => parcelAgency.collectingSchedule.find((scheduleEntry) => scheduleEntry.term === this.term) && this.agencyNumbers.includes(parcelAgency.capAgency),
        );

        return parcel.active && matchingParcelAgencies.length > 0;
      });

      allParcels.push(...validParcels);
      return allParcels;
    }, []);

    return parcels;
  }

  get agencyNumbers(): string[] {
    return this.agencies.map((agency: Agency) => agency.capAgency);
  }

  // Mixins
  isDirty() {
    return this.hasChanges;
  }

  // Hooks
  async created() {
    const currentYear = (new Date()).getFullYear();

    for (let i = 0; i < 4; i += 1) {
      const relevantYear = currentYear + i - 2;
      this.escrowYearItems.push(relevantYear.toString());
    }

    [, this.year] = this.escrowYearItems;
    this.term = this.termItems[0].value;

    // Set the reported date editable state
    const columnDef = this.columnDefs.find((def) => def.field === 'reportedDate');
    columnDef.editable = !this.isExactlyUser && !this.isExactlyTrainee;
  }

  // Methods
  onGridReadyComplete() {
    this.gridApi.showNoRowsOverlay();
    // Commenting this line because, the Property 'getSortModel' does not exist on type 'GridApi<any>'
    // this.gridApi.setSortModel([{
    //   colId: 'parcelNumber',
    //   sort: 'asc',
    // }]);
  }

  onSortChanged(params: SortChangedEvent) {
    this.onBackgroundSortChanged(params);
    this.refreshRows();
  }

  refreshRows() {
    this.results = [];
    this.gridApi.setRowData([]);

    this.getAllData();
  }

  getParams() {
    return {
      advanced_search: this.advancedSearch,
      search_type: 'escrow_history',
      limit_final_query_flag: true,
    };
  }

  async getRows(params: any, limit: number, offset: number) {
    const finalParams = { limit, offset, ...params };

    return this.makeCancellableRequest(this.loanService.getAllLoans, finalParams)
      .then((value) => {
        const { results } = value;
        return results;
      });
  }

  convertResults(results: Loan[]): ParcelSummary[] {
    const parcels = results.reduce<Parcel[]>((allParcels: Parcel[], loan: Loan) => {
      const validParcels = loan.parcels.filter((parcel) => {
        const matchingParcelAgencies = parcel.agencies.filter(
          (parcelAgency) => parcelAgency.collectingSchedule.find((scheduleEntry) => scheduleEntry.term === this.term) && this.agencyNumbers.includes(parcelAgency.capAgency),
        );

        return parcel.active && matchingParcelAgencies.length > 0;
      });

      allParcels.push(...validParcels);
      return allParcels;
    }, []);

    let parcelSummaries = parcels.reduce((allSummaries, parcel) => {
      // Get the relevant loan
      const loan: Loan = results.find((candidateLoan) => candidateLoan.loanNumber === parcel.loanNumber);

      // Get the relevant parcel agencies for each parcel - each gets a row
      const relevantParcelAgencies: ParcelAgency[] = this.agencyNumbers.reduce<ParcelAgency[]>((allParcelAgencies, agencyNumber) => {
        const foundParcelAgency = parcel.agencies.find((agency) => agency.capAgency && agency.capAgency === agencyNumber);
        const matchingScheduleEntry = foundParcelAgency && foundParcelAgency.collectingSchedule.find((scheduleEntry) => scheduleEntry.term === this.term);

        if (foundParcelAgency && matchingScheduleEntry) {
          allParcelAgencies.push(foundParcelAgency);
        }

        return allParcelAgencies;
      }, []);

      const relevantEscrowHistories: IEscrowHistory[] = relevantParcelAgencies.map(
        (parcelAgency) => parcelAgency.escrowHistory.find((historyEntry) => historyEntry.year === this.year && historyEntry.term === this.term),
      );

      // Build the parcel summaries to show the user
      // Every parcel agency remaining matches term picked
      relevantParcelAgencies.forEach((agency, index) => {
        const existingHistory = relevantEscrowHistories[index];

        allSummaries.push({
          parcel,
          loan,
          agency,

          parcelId: parcel.parcelId,
          agencyNumber: agency.capAgency,
          agencyId: agency.agencyId,
          lenderId: loan.lenderId,
          lenderNumber: parcel.lenderNumber,
          loanId: loan.loanId,
          loanNumber: parcel.loanNumber,
          addressSummary: parcel.address ? Address.firstLineAddress(parcel.address.value) : '',
          name: loan.companyName || loan.borrowerName,
          type: parcel.parcelType,
          parcelNumber: parcel.parcelNumber,
          verified: parcel.verified,
          problem: parcel.problem && parcel.problem.verified ? parcel.problem.verified : false,
          parcelNotes: parcel.parcelNotes,
          reportedDate: existingHistory ? existingHistory.reportedDate : null,
          batchNumber: existingHistory ? existingHistory.batchNumber : null,
          parcelAgencyId: agency.parcelAgencyId,
          escrowHistory: existingHistory || null,
          amountReported: existingHistory && existingHistory.amountReported ? existingHistory.amountReported.toFixed(2) : null,
          zeroVerifiedReason: existingHistory ? existingHistory.zeroVerifiedReason : null,
          existing: Boolean(existingHistory),
        });
      });

      return allSummaries;
    }, []);

    parcelSummaries = parcelSummaries.map(
      (parcelSummary, index) => ({ ...parcelSummary, index }),
    ).filter((parcelSummary) => this.reportDateFilter(parcelSummary.reportedDate));
    this.recalculateTotalTaxesDue();

    return parcelSummaries;
  }

  markAll(key: string, value: any) {
    if (key === 'reportedDate') {
      if (!(this.$refs.dateForm as any).validate()) {
        return;
      }

      this.results.forEach((parcel: ParcelSummary) => {
        if (this.overwriteCheckbox || (!this.overwriteCheckbox && (!parcel[key]))) {
          this.addToChangeList(value, parcel, key);
          parcel[key] = value;
        }
      });
    } else {
      this.results.forEach((parcel: ParcelSummary) => {
        this.addToChangeList(value, parcel, key);
        parcel[key] = value;
      });
    }

    this.gridApi.refreshCells({
      columns: [key],
    });
  }

  setCurrentDate() {
    this.selectedDate = DateTime.local().toFormat('MM/dd/yyyy');
  }

  formatAsCurrency(val: number) {
    let temp = String(val.toFixed(2));
    if (temp.length > 6) { // "ddd.cc"
      temp = `${temp.substring(0, temp.length - 6)},${temp.slice(temp.length - 6)}`;
    }
    if (temp.length > 10) { // "ddd,ddd.cc"
      temp = `${temp.substring(0, temp.length - 10)},${temp.slice(temp.length - 10)}`;
    }
    if (temp.length > 14) { // "ddd,ddd,ddd.cc"
      temp = `${temp.substring(0, temp.length - 14)},${temp.slice(temp.length - 14)}`;
    }
    return `$${temp}`;
  }

  recalculateTotalTaxesDue(summaries: ParcelSummary[] = this.results) {
    let temp = 0;

    summaries.forEach((parcelsummary) => {
      const val = Number(parcelsummary.amountReported);
      if (Number.isFinite(val)) {
        temp += val;
      }
    });

    this.calculatedTotalTaxesDue = this.formatAsCurrency(temp);
  }

  handleFilteredResults(loanIds: Array<string>) {
    const filteredResults = this.results.filter((value) => loanIds.includes(value.loanId));
    this.recalculateTotalTaxesDue(filteredResults)
  }

  handleCellChangeEvent(event: CellValueChangedEvent) {
    const data: ParcelSummary = event.node.data as ParcelSummary;
    const key = event.colDef.field;
    if (!(data.loanId in this.protectedCells)) {
      this.protectedCells[data.loanId] = { };
      this.protectedCells[data.loanId][key] = event.oldValue === true
    }

    if (event.oldValue === true && (key === 'verified' || key === 'problem') && this.protectedCells[data.loanId][key] === true) {
      event.node.data[key] = true;
      event.node.setData(event.node.data);
      return;
    }

    event.node.setData(this.addToChangeList(event.newValue, event.node.data as ParcelSummary, event.colDef.field));

    if (event.colDef.field === 'amountReported') {
      this.recalculateTotalTaxesDue();
    }
  }

  addToChangeList(value: any, summary: ParcelSummary, key: string) {
    const header = key;
    const escrowHeaders = ['amountReported', 'zeroVerifiedReason', 'reportedDate', 'batchNumber'];

    if (key !== 'verified') {
      summary.verified = false;
      this.addChangePatch(summary.verified, 'verified', summary, escrowHeaders);
    }

    summary[header] = value;
    this.addChangePatch(value, header, summary, escrowHeaders);

    return summary;
  }

  addChangePatch(value: any, field: string, summary: ParcelSummary, exclusions: string[] = []) {
    const patchMap = this.changeMap.get(summary) || new Map();
    const createEntry = this.createMap.get(summary) || {};
    const converter = this.converterMap.get(field);

    if (!summary.existing && exclusions.findIndex((escrowHeader) => escrowHeader === field) !== -1) {
      createEntry[snakeCase(field)] = value;
      this.createMap.set(summary, createEntry);
      this.createMap = new Map(this.createMap);
    } else if (converter) {
      patchMap.set(field, converter(value, summary)[0]);
      this.changeMap.set(summary, patchMap);
      this.changeMap = new Map(this.changeMap);
    } else {
      patchMap.set(field, {
        op: JsonPatchOperator.replace,
        path: field,
        value,
      });
      this.changeMap.set(summary, patchMap);
      this.changeMap = new Map(this.changeMap);
    }
  }

  async submitChanges() {
    const payload: JsonPatchPayload = [];
    this.isSaving = true;

    this.changeMap.forEach((patchMap: Map<string, JsonPatchEntry>, summary: ParcelSummary) => {
      const parcelPatches = Array.from(patchMap.values());
      parcelPatches.forEach((patch) => {
        patch.path = `/${summary.loanId}${patch.path}`;
      });
      payload.push(...parcelPatches);
    });

    this.createMap.forEach((entry: any, summary: ParcelSummary) => {
      // Determine the relevant collecting schedule entries
      // If there are matching lender entries, only use those
      // If not, use Capital entries
      let relevantEntries = summary.agency.collectingSchedule.filter(
        (collectingScheduleEntry) => collectingScheduleEntry.lenderId === summary.lenderId,
      );

      if (relevantEntries.length === 0) {
        relevantEntries = summary.agency.collectingSchedule.filter(
          (collectingScheduleEntry) => !collectingScheduleEntry.lenderId,
        );
      }

      const collectingScheduleEntries = relevantEntries.filter(
        (scheduleEntry) => scheduleEntry.term === this.term && (!scheduleEntry.lenderId || scheduleEntry.lenderId === summary.loan.lenderId),
      );

      const dueDates = collectingScheduleEntries.length > 0
        ? collectingScheduleEntries.map((scheduleEntry) => scheduleEntry.dueDate.value)
        : [null];

      const createEntries = dueDates.map((dueDate) => ({
        ...entry,
        due_date: dueDate,
        agency_id: summary.agencyId,
        parcel_id: summary.parcelId,
        parcel_agency_id: summary.parcelAgencyId,
        year: this.year,
        term: this.term,
      }));

      createEntries.forEach((createEntry) => {
        payload.push({
          op: JsonPatchOperator.add,
          path: `/${summary.loanId}/parcels/${summary.parcelId}/agencies/${summary.parcelAgencyId}/escrow_history/-`,
          value: createEntry,
        });
      })
    });

    try {
      const response = await this.loanService.batchPatchLoans(payload);
      this.showSuccess(`Updated ${response.length} loan(s) successfully.`);
    // Cast err to AxiosError - Property 'response' does not exist on type 'unknown'.Vetur
    } catch (err) {
      const e = err as AxiosError
      if (e.response && e.response.status >= 400) {
        this.showError(`Could not update loans - ${e.response.data.message}`);
      }
    } finally {
      this.isSaving = false;
      this.changeMap = new Map();
      this.createMap = new Map();
      this.protectedCells = {};

      this.refreshRows();
    }
  }

  reportDateFilter(value: string): boolean {
    return (this.displayReportedDateRows && value && value.length > 2) || (!value || value.length === 0);
  }

  private calculateDataOptions(): XLSWriteOptions[] {
    const typedColumns = this.columnDefs.filter((columnDef) => Boolean(columnDef.type));

    return typedColumns.map((column) => {
      const type = Array.isArray(column.type) ? column.type[0] : column.type;

      if (type === 'verified') {
        return {
          header: column.field,
          type: 'boolean',
        }
      }

      return {
        header: column.field,
        type: column.type as XLSTypes,
      };
    });
  }

  exportTable() {
    this.exportData(new ExportDataParams({
      file: 'EscrowQuickEntry',
    }))
  }

  exportData(params: ExportDataParams) {
    const pickSet = new Set(params.ignoreGrid ? [] : this.columnDefs.map((colDef) => colDef.field));
    params.inclusions.forEach((inclusion) => pickSet.add(inclusion));
    params.exclusions.forEach((exclusion) => pickSet.delete(exclusion));

    const parsedData = this.results.map((row) => pick(row, Array.from(pickSet)));
    if (params.format === 'xlsx') {
      const wb = buildOnePageWorkBook(parsedData, this.calculateDataOptions(), 'Report');
      XLSX.writeFile(wb, `${params.file}.xlsx`, {
        type: 'file',
      });
    } else if (params.format === 'csv') {
      const csv = Papa.unparse(parsedData);
      const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });

      saveAs(blob, `${params.file}.csv`);
    }
  }
}
